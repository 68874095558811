<template>
    <!--    我的收藏-->
    <div class="page home myCollection">
        <div class="plateBox">
            <el-row>
                <el-col :span="8" class="flex_l_c">
                    <el-input size="small" v-model="srhStr" placeholder="请输入藏品名称或资源名称" clearable class="m_r1"></el-input>
                    <el-button type="primary" @click="refreshList(1)" size="small" icon="el-icon-search">查询</el-button>
                </el-col>
                <el-col :span="16" class="text_right">
                    <el-button type="danger" size="small" @click="del()" :disabled="dataListSelections.length <= 0"
                               plain>移除收藏
                    </el-button>
                </el-col>
            </el-row>
            <el-table
                    :data="dataList"
                    v-loading="loading"
                    size="small"
                    height="calc(100vh - 300px)"
                    @selection-change="selectionChangeHandle"
                    class="table">
                <el-table-column
                        type="selection"
                        header-align="center"
                        align="center"
                        width="50">
                </el-table-column>
                <el-table-column prop="exhibitionId" label="图片" width="110">
                    <template slot-scope="scope">
                        <el-image fit="contain" :src="scope.row.thumb_path" class="itemImg"
                                  style="width: 100px; height: 100px;">
                            <el-image slot="placeholder" fit="contain" class="itemImg"
                                      :src="require('@/assets/img/thumbnail.png')" style="width: 100px; height: 100px"></el-image>
                            <el-image slot="error" fit="contain" class="itemImg"
                                      :src="require('@/assets/img/thumbnail.png')" style="width: 100px; height: 100px"></el-image>
                        </el-image>
                    </template>
                </el-table-column>
                <el-table-column show-overflow-tooltip prop="collection_num" label="藏品编号"></el-table-column>
                <el-table-column show-overflow-tooltip prop="collection_name" label="藏品名称"></el-table-column>
                <el-table-column show-overflow-tooltip prop="resource_name" label="资源名称"></el-table-column>
                <el-table-column
                        prop="name"
                        label="当前资源">
                    <template slot-scope="scope">
                        <div v-if="scope.row.resource_types!=null">
                            <!--<img :src="require('@/assets/img/threeIcon.png')" class="resourcesIcon">
                            <img :src="require('@/assets/img/mp4Icon.png')" class="resourcesIcon">-->
                            <img v-show="scope.row.resource_types.indexOf('0') !== -1"
                                 :src="require('@/assets/img/threeIcon.png')" class="resourcesIcon">
                            <img v-show="scope.row.resource_types.indexOf('1') !== -1"
                                 :src="require('@/assets/img/imgIcon.png')" class="resourcesIcon">
                            <img v-show="scope.row.resource_types.indexOf('2') !== -1"
                                 :src="require('@/assets/img/audioIcon.png')" class="resourcesIcon">
                            <img v-show="scope.row.resource_types.indexOf('3') !== -1"
                                 :src="require('@/assets/img/mp4Icon.png')" class="resourcesIcon">
                            <img v-show="scope.row.resource_types.indexOf('4') !== -1" style="float:left"
                                 :src="require('@/assets/img/xlsIcon.png')" class="resourcesIcon">
                            <img v-show="scope.row.resource_types.indexOf('5') !== -1" style="float:left"
                                 :src="require('@/assets/img/word.png')" class="resourcesIcon">
                            <img v-show="scope.row.resource_types.indexOf('6') !== -1" style="float:left"
                                 :src="require('@/assets/img/zipIcon.png')" class="resourcesIcon">
                            <img v-show="scope.row.resource_types.indexOf('7') !== -1" style="float:left"
                                 :src="require('@/assets/img/document.png')" class="resourcesIcon">
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="column_name"
                        label="类别" show-overflow-tooltip>
                </el-table-column>
                <el-table-column
                        prop="resource_keys"
                        label="标签" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <div v-if="scope.row.resource_keys!=null&& scope.row.resource_keys.length > 0">
                            <span style="margin-right: 5px"
                                  v-for="(item,index) in scope.row.resource_keys">{{item}};</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                        fixed="right"
                        width="150"
                        label="操作">
                    <template slot-scope="scope">
                        <el-button type="text" v-show="hasPermission('home:details:view')"
                                   @click="view(scope.row)">详情</el-button>
                        <el-button type="text" v-show="hasPermission('myResource:list:cancelCollection')"
                                   @click="del(scope.row.id)">移出收藏</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                    @size-change="sizeChangeHandle"
                    @current-change="currentChangeHandle"
                    :current-page.sync="pageNo"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size.sync="pageSize"
                    :total="total"
                    background
                    layout="total, sizes, prev, pager, next, jumper">
            </el-pagination>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                srhStr: '',
                dataList: [],
                pageNo: 1,
                pageSize: 10,
                total: 0,
                dataListSelections: [],
                loading: false
            }
        },
        created() {
            let srhData = JSON.parse(sessionStorage.getItem('myCollectionOpt'))
            if(srhData != null && srhData) {
                this.srhStr = srhData.srhStr
                this.pageNo = parseInt(srhData.pageNo);
            }
            sessionStorage.removeItem('myCollectionOpt')
        },
        mounted() {
            this.refreshList()
        },
        methods: {
            // 查询
            refreshList(type) {
                if(type == 1) {
                    this.pageNo = 1
                }
                this.$axios(this.api.rcPage.homeCollectionList, {
                    'current': this.pageNo,
                    'size': this.pageSize,
                    'name': this.srhStr,
                }, 'get').then(res => {
                    if (res.status) {
                        this.total = parseInt(res.data.total);
                        this.dataList = res.data.records;
                    }
                })
            },
            // 详情
            view(row) {
                let opt = {
                    srhStr: this.srhStr,
                    pageNo: this.pageNo,
                }
                sessionStorage.setItem('myCollectionOpt', JSON.stringify(opt))
                var newRow = row
                newRow.id = newRow.foreign_key_id
                sessionStorage.setItem('dizhi','/dyyg/home/myCollection')
                this.$router.push({
                    path: '/dyyg/home/retrievedDetails',
                    query: newRow
                });
            },
            // 移出收藏
            del(id) {
                let ids = []
                if (id != null) {
                    ids.push(id)
                } else {
                    ids = this.dataListSelections.map(item => {
                        return item.id
                    })
                }
                this.$confirm(`确定取消收藏所选项吗？`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$axios(this.api.rcPage.collectionRemoveByIds, ids, 'post').then(data => {
                        if (data && data.status) {
                            this.$message.success('移出成功')
                            if (this.pageNo>1 && this.dataList.length == ids.length){
                                this.pageNo--;
                            }
                            this.refreshList()
                        } else {
                            this.$message.error('移出失败')
                        }
                    })
                })
            },
            // 多选
            selectionChangeHandle(val) {
                this.dataListSelections = val
            },
            // 每页数
            sizeChangeHandle(val) {
                this.pageSize = val
                this.pageNo = 1
                this.refreshList()
            },
            // 当前页
            currentChangeHandle(val) {
                this.pageNo = val
                this.refreshList()
            },
        }
    }
</script>

<style scoped>
    .resourcesIcon {
        width: 2rem;
        margin: 0 0.5rem;
    }
</style>
